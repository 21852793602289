const style = theme => ({
    mainContainer: {
        maxHeight: "1000px",
        overflow: "hidden",
        // position: "absolute",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
        // height: "100px",
        height: "88vh",
        top: "0",
        left: "0",
        width: "100%",
        "& h1": {
            color: "black",
            position: "absolute",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, -150%)",
        },
        "& h2": {
            color: "black",
            position: "absolute",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, 0%)",
        },
        "& p": {
            textDecoration: "underline",
            position: "absolute",
            left: "50%",
            marginRight: "-50%",
            transform: "translate(-50%, 0%)",
            color: "blue",
            cursor: "pointer"
        }
    },
    logo: {
        width: "240px",
        // width: "450px",
        minWidth: "250px",
        position: "fixed",
        top: "15px",
        left: "28px",
        zIndex: "1000",
        "&:hover": {
            background: "unset",
            cursor: "pointer"
        },
        "@media (max-width: 550px)": {
            width: "calc(100vw - 20%)",
            top: "-2px",
            left: "19px",
        },
    },
});
export default style;