import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// import ClearCache from "./ClearCache";
import Cookies from "universal-cookie";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

import './index.css';
import * as serviceWorker from './serviceWorker';
import './assets/css/_plugin-perfect-scrollbar.css';
import './assets/css/_plugin-react-table.css';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';

const Home = React.lazy(() => import('./pages/Home'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const InternalProfileSearch = React.lazy(() => import('./pages/InternalProfileSearch'));
const ProtectedRoute = React.lazy(() => import('./components/ProtectedRoute'));
const hist = createBrowserHistory();

const maintenance = false;
const cookies = new Cookies('captchaTrigger', false, { path: '/' });
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabName: null,
            cookiesChanged: false,
        };
    }
    componentDidMount() {
        cookies.addChangeListener(event => {
            this.setState({ cookiesChanged: !this.state.cookiesChanged });
        });
    }
    getLandingTab = (tabName) => {
        this.setState({ tabName });
    }
    setCookiesChanged = () => {
        this.setState({ cookiesChanged: !this.state.cookiesChanged });
    }
    // render() {
    //     return (
    //         <ClearCache>
    //             {({ loading, isLatestVersion, refreshCacheAndReload }) => {
    //                 if (loading) return null;
    //                 if (!loading && !isLatestVersion) {
    //                     refreshCacheAndReload();
    //                 }
    //                 return (
    //                     !maintenance ?
    //                         <React.Suspense fallback={<span></span>}>
    //                             <Router history={hist}>
    //                                 <Switch>
    //                                     <Route path="/loginPage" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'login'} getLandingTab={this.getLandingTab} />} />
    //                                     <Route path="/changePassword" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'changePassword'} />} />
    //                                     <Route path="/forgotPassword" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'forgotPassword'} />} />
    //                                     <Route path="/password_reset" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'resetForgottenPassword'} />} />
    //                                     {/* <Route path="/signout" component={Home} /> */}
    //                                     <Route path="/signout" render={(props) => <Home logout {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} tabName={this.state.tabName ? this.state.tabName : ""} />} />
    //                                     <ProtectedRoute path="/_internalSearch" component={InternalProfileSearch} role={'INTERNAL_LOOKUP'} />
    //                                     {/* <Route path="/" component={Home} /> */}
    //                                     <Route path="/" render={(props) => <Home {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} tabName={this.state.tabName ? this.state.tabName : ""} />} />
    //                                     <Redirect from="/" to={cookies && cookies.get("landingPage") ? cookies.get("landingPage") : "/home"} />
    //                                     {/* <Redirect from="*" to="/notfound" /> */}
    //                                     <Route path="*" component={NotFound} />
    //                                 </Switch>
    //                             </Router>
    //                         </React.Suspense>
    //                         :
    //                         <React.Suspense fallback={<span></span>}>
    //                             <Router history={hist}>
    //                                 <Switch>
    //                                     <Route path="/" component={Maintenance} />
    //                                     <Route path="*" component={Maintenance} />
    //                                 </Switch>
    //                             </Router>
    //                         </React.Suspense>
    //                 )
    //             }}
    //         </ClearCache>
    //     )
    // }
    render() {
        return (
            <CacheBuster
                currentVersion={version}
                isEnabled={true} 
                isVerboseMode={false} 
                metaFileDirectory={'.'} 
            >
                {
                    !maintenance ?
                        <React.Suspense fallback={<span></span>}>
                            <Router history={hist}>
                                <Switch>
                                    <Route path="/loginPage" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'login'} getLandingTab={this.getLandingTab} />} />
                                    <Route path="/changePassword" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'changePassword'} />} />
                                    <Route path="/forgotPassword" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'forgotPassword'} />} />
                                    <Route path="/password_reset" render={(props) => <LoginPage {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} componentMode={'resetForgottenPassword'} />} />
                                    {/* <Route path="/signout" component={Home} /> */}
                                    <Route path="/signout" render={(props) => <Home logout {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} tabName={this.state.tabName ? this.state.tabName : ""} />} />
                                    <ProtectedRoute path="/_internalSearch" component={InternalProfileSearch} role={'INTERNAL_LOOKUP'} />
                                    {/* <Route path="/" component={Home} /> */}
                                    <Route path="/" render={(props) => <Home {...props} cookies={cookies} hist={hist} setCookiesChanged={this.setCookiesChanged} tabName={this.state.tabName ? this.state.tabName : ""} />} />
                                    <Redirect from="/" to={cookies && cookies.get("landingPage") ? cookies.get("landingPage") : "/home"} />
                                    {/* <Redirect from="*" to="/notfound" /> */}
                                    <Route path="*" component={NotFound} />
                                </Switch>
                            </Router>
                        </React.Suspense>
                        :
                        <React.Suspense fallback={<span></span>}>
                            <Router history={hist}>
                                <Switch>
                                    <Route path="/" component={Maintenance} />
                                    <Route path="*" component={Maintenance} />
                                </Switch>
                            </Router>
                        </React.Suspense>
                }
            </CacheBuster>
        )
    }
}

ReactDOM.render(
    <App />,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
