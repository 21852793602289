import React from 'react';
import style from './notFoundStyle.js';
import withStyles from "@material-ui/core/styles/withStyles";

class NotFound extends React.Component {
    componentDidMount() {
        this.props.triggerSearch(false);
    }
    redirect = () => {
        window.location.href = "/home";
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.mainContainer}>
                <h1>We are unable to find the page you are looking for.</h1>
                <p onClick={() => this.redirect()}>Please click here to go back to our Homepage.</p>
            </div>
        );
    }
};
export default withStyles(style)(NotFound);