import React from 'react';
import style from './maintenanceStyle.js';
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "@material-ui/core/Button";
// import Header from "../../components/Header/Header";
// import HeaderLinks from "../../components/Header/HeaderLinks";
// import DrawerLinks from "../../components/Header/DrawerLinks";
// const dashboardRoutes = ["/home"];

class Maintenance extends React.Component {
    render() {
        const { classes } = this.props;
        // const image = require("../../assets/img/Dibots Logo 2.png");
        const image = require("../../assets/img/dibots_new_logo/dibots-logo-homepage.svg");
        return (
            <div>
                {/* <Header
                    color="white"
                    routes={dashboardRoutes}
                    brand="DiBots Portal"
                    rightLinks={<DrawerLinks />}
                    midLinks={<HeaderLinks authenticated={false} logout={this.logout} location={this.props.location}/>}
                    absolute
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                    triggerSearch={this.triggerSearch}
                    image={image}
                    isFullScreen={false}
                    roles={[]}
                /> */}
                <Button
                    className={classes.logo}
                    // href={"/home"}
                    disableRipple
                >
                    <img
                        alt="Dibots Logo"
                        src={image}
                        className={classes.logo}
                    />
                </Button>
                <div className={classes.mainContainer}>
                    <h1>Our website is undergoing maintenance. We'll be back soon.</h1>
                    <h2>We thank you for your patience and hope to see you soon!</h2>
                </div>
            </div>
        );
    }
};
export default withStyles(style)(Maintenance);